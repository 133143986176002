.model-version-table {
    margin-bottom: 25px;
}

.model-version-table .model-version-link {
    cursor: pointer;
}

.model-version-table .model-version-status-icon {
    font-size: 13px;
}
