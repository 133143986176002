.model-view-content .table-name {
  font-size: 18px;
}

.model-version-table-header {
  display: flex;
}

.active-toggle {
  margin-left: 10px;
}

.ModelView-run-buttons{
  margin-top: 4px;
}

.ModelView-run-buttons .btn {
  margin-left: 16px;
}
