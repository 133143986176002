.breadcrumb-title {
  max-width: 500px;
  vertical-align: bottom;
  display: inline-block;
}

.breadcrumb-chevron {
  font-size: 75%;
  margin-left: 10px;
  margin-right: 8px;
  vertical-align: 4px;
}
